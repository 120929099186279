/** @jsx jsx */

import { graphql } from "gatsby"
import { MeningPage } from "../pages-base/MeningPage"

export default MeningPage("en")

export const query = graphql`
  query MeningenEnPage {
    allGoogleSpreadsheetMeningen(filter: { approved: { eq: "TRUE" } }) {
      edges {
        node {
          id
          thumbnail
          shareUrl
          provincie
          approved
        }
      }
    }
    datoCmsMeningenPage(locale: { eq: "en" }) {
      title
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      content {
        ... on DatoCmsBanner {
          ...BannerFragment
        }
        ... on DatoCmsIntro {
          ...IntroFragment
        }
        ... on DatoCmsDoubleImage {
          ...DoubleImageFragment
        }
        ... on DatoCmsTextBlock {
          ...TextBlockFragment
        }
        ... on DatoCmsTextImageBlock {
          ...TextImageBlockFragment
        }
        ... on DatoCmsTextImageBlockLeft {
          ...TextImageBlockLeftFragment
        }
        ... on DatoCmsQuote {
          ...QuoteFragment
        }
        ... on DatoCmsEventsList {
          ...EventsListFragment
        }
        ... on DatoCmsMagazine {
          ...MagazineFragment
        }
        ... on DatoCmsSocial {
          ...SocialFragment
        }
      }
    }
  }
`
